import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import { accessApi, apiPortal, responseApi } from '@/provider'
import { toEncrypt } from "../provider";
import mySettingsObject from 'my-app-settings'


let myValue = mySettingsObject.MODE_ON
// console.log(myValue, 'test config')

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    inboxFitur: true,
    redeemFitur: false,
    storeFitur: true,
    bannerFitur: false,
    isLoading: true,
    user: "6281294400076",
    profile: null,
    token: null,
    url: null,
    listgame: [],
    detailgame: {},
    tokenLife: null,
    statusStore: false,
    gameId: null,
    gameIdUnix: null,
    coin: 0,
    point: 0,
    dataPoint: [],
    reward: null,
    mode: myValue,
    // reward:[
      // {id: 1, name: 'Apple Watch',  url: require("@/assets/reward/star_applewatch.png")},
      // {id: 2, name: 'Galaxy Fit', url: require("@/assets/reward/star_galaxyfit.png")},
      // {id: 3, name: 'Iphone', url: require("@/assets/reward/star_iphone.png")},
      // {id: 4, name: 'Motor',  url: require("@/assets/reward/star_motor.png")},
      // {id: 5, name: 'Playstation',  url: require("@/assets/reward/star_playstation.png")},
      // {id: 6, name: 'Smart TV', url: require("@/assets/reward/star_smarttv.png")}
    // ],
    bannerList: [
      // {
      //   name: 'Pesta Reward',
      //   image: require('@/assets/pesta-reward.png'),
      //   url: 'leaderboard',
      // },
      // {
      //   name: 'Hadiah Langsung',
      //   image: require('@/assets/hadiah-langsung.png'),
      //   url: 'home',
      // },
    ],
    leaderboardList:[
      // {
      //   msisdn: "62812345xxxx",
      //   point: "10000"
      // },
      // {
      //   msisdn: "62812111xxxx",
      //   point: "9000"
      // },
      // {
      //   msisdn: "62822443xxxx",
      //   point: "8000"
      // },
      // {
      //   msisdn: "62812645xxxx",
      //   point: "7000"
      // },
      // {
      //   msisdn: "62822349xxxx",
      //   point: "6000"
      // },
      // {
      //   msisdn: "62822340xxxx",
      //   point: "5000"
      // },
      // {
      //   msisdn: "62811340xxxx",
      //   point: "4500"
      // },
      // {
      //   msisdn: "62812040xxxx",
      //   point: "3000"
      // },
      // {
      //   msisdn: "62822390xxxx",
      //   point: "2500"
      // },
      // {
      //   msisdn: "62811140xxxx",
      //   point: "1000"
      // },
    ],
   
   
    inboxList: [],
    storeList: [
      {
        id: 1,
        name: "Berlangganan per hari",
        image: null,
        price: 1100,
        description: "Rp.1100/sms/hari",
        url: "sms://"
      },
      {
        id: 2,
        name: "Berlangganan per 2 hari",
        image: null,
        price: 2200,
        description: "Rp.2200/sms/2hari",
        url: "sms://"
      },
      {
        id: 3,
        name: "Berlangganan per 3 hari",
        image: null,
        price: 3300,
        description: "Rp.3300/sms/3hari",
        url: "sms://"
      },
      {
        id: 4,
        name: "Berlangganan per minggu",
        image: null,
        price: 5500,
        description: "Rp.5500/sms/minggu",
        url: "sms://"
      },
      {
        id: 5,
        name: "Berlangganan per 10 hari",
        image: null,
        price: 8800,
        description: "Rp.8800/sms/10hari",
        url: "sms://"
      },
      {
        id: 6,
        name: "Pembelian untuk 3 sesi (token)",
        image: null,
        price: 1100,
        description: "Rp.1100/sms",
        url: "sms://"
      }
    ],
    winnerList: [],
    rewardList: [],
    rewardDetail: {},
    // appKey: 'eyJhcHBfYWxpYXMiOiJ1dGVuZ2dvIiwiYXBwX25hbWUiOiJVbGFyIFRlbmdnbyIsImNwX2lkIjoxfQ==aGeWlvxnnXLxKA7fMKH1',
    appKey: 'eyJhcHBfYWxpYXMiOiJzbWFydGhva2kiLCJhcHBfbmFtZSI6IlNtYXJ0IEhva2kiLCJjcF9pZCI6IjUifQ==cpMISImhB7nonUnh',
    secretKey: 'Bismillah',
    telco: 'tsel',
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  getters: {
    isUser: state => state.user,
    getUser: state => state.user,
    getSecret: state => state.secretKey,
    getGameId: state => state.gameId,
    getLifeId: state => state.tokenLife,
    getCoin: state => state.coin,
    getMode: state => state.mode,
    getRedeem: state => state.redeemList,
    getStore: state => state.storeList,
    getBanner: state => state.bannerList,
    getInbox: state => state.inboxList,
    getReward: state => state.popupReward,
    getLeaderboard: state => state.leaderboardList,
    getGameIdUnix: state => state.gameIdUnix,
    getWinnerList: state => state.winnerList,
    getRewardList: state => state.rewardList,
    getRewardDetail: state => state.rewardDetail,
  },
  mutations: {
    SET_TOKEN: (state, value) => value ? (state.token = value) : (state.token = null),
    SET_USER: (state, value) => value ? (state.user = value) : (state.user = null),
    SET_URL: (state, value) => value ? (state.url = value) : (state.url = null),
    SET_LIST_GAME: (state, value) => value ? (state.listgame = value) : (state.listgame = []), 
    SET_PROFILE: (state, value) => value ? (state.profile = value) : (state.profile = null),
    SET_TOKEN_LIFE: (state, value) => value ? (state.tokenLife = value) : (state.tokenLife = null),
    SET_DATA_POINT: (state, value) => value ? (state.dataPoint = value) : (state.dataPoint = []),
    SET_GAME_ID: (state, value) => value ? (state.gameId = value) : (state.gameId = null),
    SET_DETAIL_GAME: (state, value) => value ? (state.detailgame = value) : (state.detailgame = {}),
    SET_COIN: (state, value) => value ? (state.coin = value) : (state.coin = 0),
    SET_POINT: (state, value) => value ? (state.point = value) : (state.point = 0),
    SET_REWARD: (state, value) => value ? (state.reward = value) : (state.reward = null),
    SET_MODE: (state, value) => value ? (state.mode = value) : (state.mode = 'free'),
    SET_TELCO: (state, value) => value ? (state.telco = value) : (state.telco = null),
    SET_BANNER_LIST: (state, value) => value ? (state.bannerList = value) : (state.bannerList = []),
    SET_REWARD_LIST: (state, value) => value ? (state.popupReward = value) : (state.popupReward = []),
    SET_STORE_LIST: (state, value) => value ? (state.storeList = value) : (state.storeList = []),
    SET_LEADERBOARD_LIST: (state, value) => value ? (state.leaderboardList = value) : (state.leaderboardList = []),
    SET_INBOX_LIST: (state, value) => value ? (state.inboxList = value) : (state.inboxList = []),
    SET_GAME_ID_UNIX: (state, value) => value ? (state.gameIdUnix = value) : (state.gameIdUnix = null),
    SET_WINNER_LIST: (state, value) => value ? (state.winnerList = value) : (state.winnerList = []),
    SET_HADIAH_LIST: (state, value) => value ? (state.rewardList = value) : (state.rewardList = []),
    SET_HADIAH_DETAIL: (state, value) => value ? (state.rewardDetail = value) : (state.rewardDetail = {}),
  },
  actions: {
    getProfile: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const msisdn = toEncrypt(getters.getUser, getters.getSecret) 
          const sendData = { req: msisdn }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameProfile', sendData, { headers })
          const dataProfile = await JSON.parse(responseApi(data))
          const JsonDataProfile = dataProfile
          commit('SET_TELCO', JsonDataProfile.telco)
          commit('SET_PROFILE', JsonDataProfile)
          // console.log('SET_TELCO', JsonDataProfile.telco)
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getDetail: ({commit, getters}) => {
      // console.log(getters.getMode, 'mode')
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const msisdn = toEncrypt(getters.getUser, getters.getSecret) 
          const sendData = { req: msisdn }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameDetail', sendData, { headers })
          const dataDetail = await JSON.parse(responseApi(data))
          const JsonDataDetail = dataDetail
          // console.log(JsonDataDetail)
          // commit('SET_COIN', 1)
          commit('SET_COIN', JsonDataDetail.token)
          if (getters.getMode === 'free') {
            JsonDataDetail.point = getters.getPoint
            commit('SET_POINT', JsonDataDetail.point)
          } else {
            commit('SET_POINT', JsonDataDetail.point)
          }
          
          // console.log(process.env.VUE_APP_KEY, process.env.VUE_APP_SECRET);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getGames: ({commit}) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('games', { headers })
        const dataGames = JSON.parse(responseApi(data))
        console.log(dataGames)
        commit('SET_LIST_GAME', dataGames)
      })
    },
    getLeaderboard: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const msisdn = toEncrypt(getters.getUser, getters.getSecret) 
        const sendData = { req: msisdn }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameLeaderboard', sendData, { headers })
        const dataLeaderboard = JSON.parse(responseApi(data))
        // console.log(dataLeaderboard);
        commit('SET_LEADERBOARD_LIST', dataLeaderboard)
      })
    },
    getInbox: ({commit}) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('playerInbox/list', { headers })
        commit('SET_INBOX_LIST', data.data)
      })
    },
    getBanner: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 4 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log('data banner',dataGames);
        commit('SET_BANNER_LIST', dataGames)
      })
    },
    getRedeem: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 3 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_REDEEM_LIST', dataGames)
      })
    },
    getStore: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 2 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_STORE_LIST', dataGames)
      })
    },
    getReward: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 1 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_REWARD_LIST', dataGames)
      })
    },
    getGameReward: ({commit}) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('gameReward', { headers })
        const res = responseApi(data)
        const dataReward = JSON.parse(res)
        // console.log(dataReward);
        commit('SET_REWARD', dataReward)
      })
    },
    getLife: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const unix = Math.round(+new Date()/1000);
          const unixSlice = unix.toString().slice(1, 10);
          const gameUnix = getters.getGameId+parseInt(unixSlice) 
          const datas = {
            game_id: gameUnix,
            msisdn: getters.getUser,
            type: "portal",
          }
          // console.log('data dikirim', datas);
          const request = toEncrypt(JSON.stringify(datas), getters.getSecret) 
          const sendData = { req: request }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameLife', sendData, { headers })
          const dataLife = await JSON.parse(responseApi(data))
          commit('SET_TOKEN_LIFE', dataLife.token_life)
          commit('SET_GAME_ID_UNIX', gameUnix)
          // console.log('getlife',dataLife);
          resolve(dataLife.token_life);
        }).catch(error => {
          reject(error)
        })
      })
    },
    storePoint: async ({commit, getters}, point) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const datas = {
            game_id: getters.getGameIdUnix,
            msisdn: getters.getUser,
            token_id: getters.getLifeId,
            data: point,
          }
          // console.log('store data', datas);
          const request = toEncrypt(JSON.stringify(datas), getters.getSecret)
          const sendData = { req: request }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gamePoint', sendData, { headers })
          const dataPoint = await JSON.parse(responseApi(data))
          // console.log('response store', dataPoint)
          commit('SET_DATA_POINT', dataPoint)
          resolve(dataPoint)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    // require('@/assets/reward/ps5.png'),
    listGames:({commit})=>{
     const data = [
        {
            id: 2122890420,
            game_name: "Lucky Racer",
            description: "Embark on an exhilarating journey with Lucky Racer, the ultimate adrenaline-pumping game! Navigate through thrilling tracks, dodge obstacles, and challenge your reflexes in this high-speed racing adventure. Test your luck with power-ups and unlock an array of sleek and powerful cars. Are you ready to race your way to victory and become the luckiest racer on the track? Buckle up and hit the road with Lucky Racer for an electrifying gaming experience like never before!",
            image: require('@/assets/thumbnail/r_1.png'),
            banner: [],
            link:"games/LuckyRacer/",
            type: 1,
            type_name: 1
        },
        {
          id: 2122890421,
          game_name: "Lucky Soccer Challenge",
          description: "shoot, and score your way to victory in Lucky Soccer Challenge! This thrilling game combines the excitement of soccer with a touch of luck. Take on challenges, outsmart opponents, and unlock amazing rewards as you showcase your soccer skills. With a bit of luck, you might just lead your team to championship glory! Get ready to experience the perfect blend of skill and chance in Lucky Soccer Challenge​",
          image: require('@/assets/thumbnail/r_2.png'),
          banner: [],
          link:"games/LuckySoccerChallange/",
          type: 1,
          type_name: 2
      },
      {
        id: 2122890422,
        game_name: "Wings Rocket",
        description: "Engage in high-stakes aerial combat with Wings Rocket! Strap into the cockpit, unleash the power of cutting-edge fighter jets, and take on a relentless alien invasion. Dodge enemy fire, execute daring maneuvers, and unleash a barrage of missiles to defend Earth from extraterrestrial threats. Do you have the skills to outsmart the alien forces and emerge as the ultimate jet fighter ace? It's time to soar through the skies and save the world in this heart-pounding, action-packed adventure!",
        image: require('@/assets/thumbnail/r_3.png'),
        banner: [],
        link:"games/WingsRocket/",
        type: 1,
        type_name: 3
    },
    {
      id: 2134890423,
      game_name: "Lucky Rally",
      description: "Rev up your engines and conquer the off-road terrain in Rally Quest! Immerse yourself in the adrenaline-fueled world of rally racing, where precision meets high-speed thrills. Navigate challenging tracks, master hairpin turns, and experience the rush of rallying in powerful, customizable vehicles. Will you emerge as the rally champion, conquering every twist and turn? Get ready for the ultimate off-road adventure in Rally Quest – where the dirt road is your canvas, and victory is the ultimate destination!",
      image: require('@/assets/thumbnail/r_4.png'),
      banner: [],
      link:"games/LuckyRally/",
      type: 1,
      type_name: 4
  },
  {
    id: 1122890456,
    game_name: "Lucky Dagger",
    description: "Embark on a thrilling knife-throwing adventure as you aim for precision in the game of Knife Toss Challenge! Test your accuracy and timing skills as you throw knives towards rotating targets. Navigate through challenging levels, adjust your aim, and hit the bullseye for maximum points. Beware of the rotating obstacles and dynamic patterns that will keep you on your toes. Are you ready to embrace the excitement and become a master knife thrower? It's time to hone your skills and take on the ultimate Knife Toss Challenge",
    image: require('@/assets/thumbnail/r_5.png'),
    banner: [],
    link:"games/LuckyDagger/",
    type: 1,
    type_name: 5
  },
  {
    id: 1422890456,
    game_name: "Race Delphi",
    description: "Dive into the world of high-speed aquatic racing with Dolphin Dash! Take control of the fastest dolphins in the ocean as they compete to reach the finish line in this thrilling game. Navigate through challenging underwater tracks, perform flips and spins to gain speed boosts, and outsmart opponents to secure victory. With stunning underwater landscapes and a variety of power-ups, Dolphin Dash promises an exhilarating race to the finish line. Are you ready to lead your dolphin to glory and become the ultimate champion of the sea?",
    image: require('@/assets/thumbnail/r_6.png'),
    banner: [],
    link:"games/RaceDelphi/",
    type: 1,
    type_name: 6
  },
  {
    id: 1421110456,
    game_name: "Barusbaki",
    description: "Embark on a medical adventure with 'Virus Remedy Quest'! In this unique game, players aim to seek treatment and combat a viral outbreak. Navigate through various levels to find the right medications, boost your immunity, and ward off the spreading virus. Solve medical puzzles, collect essential supplies, and race against time to restore health. Can you become the ultimate virus-fighting hero and save the virtual world from the pandemic? It's time to embark on the 'Virus Remedy Quest' and conquer illness with strategic medical gameplay!",
    image: require('@/assets/thumbnail/r_7.png'),
    banner: [],
    link:"games/Barusbaki/",
    type: 1,
    type_name: 7
  },
  {
    id: 2321110456,
    game_name: "Fortune Ladder",
    description: "Step back in time with Fotunr – a nostalgic journey to the classic board game era! Rediscover the joy of simplicity as you roll the dice, navigate ancient pathways, and climb ladders to ascend to victory. Immerse yourself in a charming, retro-themed world where strategy meets chance. Will you conquer the challenges of the ancient board and reach the top first, or will the serpentine pitfalls set you back? Gather your friends for a timeless adventure filled with laughter, strategy, and the thrill of the climb in 'Ancient Snakes & Ladders'!",
    image: require('@/assets/thumbnail/r_8.png'),
    banner: [],
    link:"games/FortuneLadder/",
    type: 1,
    type_name: 8
  },
    ]
    commit('SET_LIST_GAME', data)
    },
    // eslint-disable-next-line no-unused-vars
   
  },
  modules: {
    // login
  }
});

<template>
  <div :class="{ loader: true, fadeout: !isLoading }" class="justify-content-center d-flex flex-wrap">
    <b-img alt="Vue load" :src="require('@/assets/loading.gif')" class="align-self-center img-loader"></b-img>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: ["isLoading"]
};
</script>

<style>
.loader {
  background-color: white;
  bottom: 0;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  /* padding-top: 10vh; */
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 2000;
  height: 100%;
  width: 100%;
}

.img-load {
    width: 90%;
    height: 90%;
}

.img-loader {
  width: 100px;;
}

.fadeout {
  animation: fadeout 0.3s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import provider from './provider'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'swiper/swiper-bundle.css'
import VueMobileDetection from 'vue-mobile-detection'
// import firebase from "firebase";
import VueCryptojs from 'vue-cryptojs'
// import mySettingsObject from 'my-app-settings'


// let myValue = mySettingsObject.MY_VARIABLE
// console.log(myValue, 'test config')

// const configOptions = {
//   apiKey: "AIzaSyC-khjVti_LvFMg_Ktj4cVW43V6oWWiLKE",
//     authDomain: "portal-game-c5b1a.firebaseapp.com",
//     projectId: "portal-game-c5b1a",
//     storageBucket: "portal-game-c5b1a.appspot.com",
//     messagingSenderId: "761276225651",
//     appId: "1:761276225651:web:f5e17c7e2dce185b56b206",
//     measurementId: "G-HZVNMGKPQ9"
// };

// firebase.initializeApp(configOptions);

Vue.use(VueMobileDetection)
Vue.use(VueCryptojs)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueAwesomeSwiper)

Vue.mixin({
  data: function() {
    return {
      appKey: 'eyJhcHBfYWxpYXMiOiJzbWFydGhva2kiLCJhcHBfbmFtZSI6IlNtYXJ0IEhva2kiLCJjcF9pZCI6IjUifQ==cpMISImhB7nonUnh'
      // appKey: 'eyJhcHBfYWxpYXMiOiJwb3J0YWxIdXRjaCIsImFwcF9uYW1lIjoiUG9ydGFsIEh1dGNoIiwiY3BfaWQiOjV900VGCKcuCgn9q0UR'
    }
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  // provider,
  render: h => h(App)
}).$mount('#app')
